.Portfolio {
    background-color: var(--charcoal);
    min-height: 85vh;
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-top: 10vmin !important;
        color: var(--gainsboro);
    }

    h2 {
        font-size: 6vmin;
    }

    h3 {
        font-size: 4vmin;
    }

    .Projects {
        width: 80vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10vmin;
        margin-bottom: 10vmin !important;

        .Project {
            align-self: stretch;
            display: flex;
            justify-content: space-evenly;
            gap: 5vmin;
            min-height: 45vh;
            justify-items: center;
            background-color: var(--gainsboro);
            padding: 5vmin !important;
            transition: 0.2s ease-in-out;
            border-radius: 5px;

            div>img {
                width: 500px;
                border-radius: 5px;
            }

            &:hover {
                transition: 0.15s ease-in-out;
            }

            $breakpoint-tablet: 900px;

            @media (max-width: $breakpoint-tablet) {
                flex-direction: column;
                height: auto !important;

                div>img {
                    width: 70vw;
                }

                div>p {
                    font-size: 3vmin;
                }

            }
        }
    }
}