.App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;

    nav {
        width: 100vw;
        height: 25vh;
    }

    .NavBar,
    .Footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .NavBar {
        width: 100vw;
        height: 20vh;
        display: flex;
        flex-direction: column;
        gap: 2vmin;
    }

    main {
        margin-top: 15vh;

        section {
            align-self: center;
            width: 100vw;
            height: 80vh;
            display: flex;
            flex-direction: column;
            gap: 2vh;
            justify-content: center;

            h1 {
                text-align: center;
                letter-spacing: 3vmin;
                opacity: 80%;
                text-transform: uppercase;
            }
        }
    }

}