.About {
    align-items: center;
    min-height: 85vh;
    height: auto !important;
    padding: 6vmin !important;

    .AboutMe {
        display: flex;
        width: 80vw;

        .AboutText {
            p {
                font-size: 2.1vmin;
            }
        }

        $breakpoint-tablet: 900px;

        @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
            align-items: center;
            gap: 10vmin;

            div {
                width: 85vw;
            }

            div:first-child {
                img {
                    width: 60vmin;
                }
            }
        }

        .HeadShot {
            display: flex;
            justify-content: center;
            align-items: center;


            img {
                border-radius: 50%;
                width: 50vmin;
            }
        }

        .AboutText {
            text-align: center;

            div:first-child {
                height: 100%;
                padding: 0vh 3vw !important;
                display: flex;
                flex-direction: column;
                gap: 2vh;
                justify-content: center;

                p,
                h2,
                h3 {
                    text-align: left;
                }
            }
        }
    }

}