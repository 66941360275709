.Home {
    align-items: center;
    background-color: var(--charcoal);
    justify-content: center;

    .Intro {
        width: 80vw;
        text-align: center;

        h1 {
            letter-spacing: 0;
            opacity: 100;
            text-transform: none;
            color: var(--cultured);
            font-size: 10vmin;
            ;
        }

        h2 {
            color: var(--mustard);
        }

        h3 {
            opacity: 75%;
            color: var(--cultured);
        }

        p {
            color: var(--cultured);
        }

    }
}