.NavBar {
    background-color: var(--charcoal);
    gap: 3vmin !important;

    .Links {
        display: flex;
        gap: 5vmin;
    }

    .Links a {
        text-decoration: none;
        font-size: 2.5vmin;
        color: var(--cultured);
        border-bottom: 2px solid transparent;


        &:hover {
            border-bottom: 2px solid var(--mustard);
        }

        $breakpoint-tablet: 900px;

        @media (max-width: $breakpoint-tablet) {
            font-size: 3.5vmin;
        }
    }

    .Navigation {
        opacity: 95%;
    }

    .Social-Media {
        opacity: 95%;
        font-size: smaller;
        display: flex;
        gap: 3vmin;

        a {
            .icon {
                font-size: 5vmin !important;

                path {
                    color: var(--cultured);
                    opacity: 85%;
                    transition: 0.2s ease-in-out;
                }
            }

            &:hover {
                .icon {
                    font-size: 5vmin !important;

                    path {
                        transition: 0.10s ease-in-out;
                        opacity: 100%;
                        color: var(--mustard);
                    }
                }
            }

            $breakpoint-tablet: 900px;

            @media (max-width: $breakpoint-tablet) {
                .icon {
                    font-size: 6vmin !important;
                }

                &:hover {
                    .icon {
                        font-size: 6vmin !important;
                        transform: scale(1.15);

                        path {
                            transition: 0.10s ease-in-out;
                            opacity: 100%;
                            color: var(--mustard);
                        }
                    }
                }
            }
        }
    }
}

.Sticky-NavBar {
    position: fixed;
    top: 0;
}

.CurrentPage {
    background-color: var(--charcoal);
    color: var(--cultured);
}