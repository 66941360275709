.Footer {
    background-color: var(--charcoal);
    height: 20vh;
    flex-direction: column;

    .Links {
        display: flex;
        gap: 5vmin;
        height: 10vh;
        align-items: center;
    }

    .Social-Media {
        opacity: 95%;
        font-size: smaller;

        a {
            .icon {
                font-size: 5vmin !important;

                path {
                    color: var(--cultured);
                    opacity: 85%;
                    transition: 0.2s ease-in-out;
                }
            }

            &:hover {
                .icon {
                    font-size: 7vmin !important;

                    path {
                        transition: 0.10s ease-in-out;
                        opacity: 100%;
                        color: var(--mustard);
                    }
                }
            }

            $breakpoint-tablet: 900px;

            @media (max-width: $breakpoint-tablet) {
                .icon {
                    font-size: 6vmin !important;
                }

                &:hover {
                    .icon {
                        font-size: 6vmin !important;
                        transform: scale(1.15);

                        path {
                            transition: 0.10s ease-in-out;
                            opacity: 100%;
                            color: var(--mustard);
                        }
                    }
                }
            }
        }
    }

    p {
        color: var(--cultured);
        font-size: 0.65em;
    }
}