:root {
  --cultured: #f9f9f9ff;
  --charcoal: #234857ff;
  --feldgrau: #54685dff;
  --desert-sand: #e3c8b8ff;
  --gainsboro: #dee4e4ff;
  --mustard: #C69E58;
}

* {
  box-sizing: border-box;
  margin: 0 !important;
  padding: 0 !important;
  color: var(--charcoal);
  font-family: 'Montserrat', sans-serif;
}

// Global Text Element Styling
h1 {
  font-size: 7vmin;
  font-weight: 100;
}

h2 {
  font-size: 6vmin;
}

h3 {
  font-size: 5vmin;
}

p {
  margin: 2vmin 0 !important;
  font-size: 2.5vmin;

  $breakpoint-tablet: 900px;

  @media (max-width: $breakpoint-tablet) {
    font-size: 3vmin;
  }
}

// Global Button Styling
button {
  background-color: var(--cultured);
  color: var(--charcoal);
  opacity: 85%;
  padding: 1vmin 2vmin !important;
  margin: 5vmin 0 !important;
  border: 0.5px solid var(--charcoal);
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  font-size: 2.5vmin;


  a {
    color: var(--charcoal);
    text-decoration: none;
  }

  &:hover {
    transition: 0.15s ease-in-out;
    opacity: 100%;
    border: 0.5px solid var(--charcoal);
    background-color: var(--mustard);

    a {
      color: var(--cultured)
    }
  }

  $breakpoint-tablet: 900px;

  @media (max-width: $breakpoint-tablet) {
    font-size: 3.5vmin;
  }
}