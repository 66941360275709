.Contact {
    align-items: center;
    height: 80vh;

    .ContactMe {
        width: 80vw;
        height: 65vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        h1 {
            justify-self: flex-start;
        }

        div {
            text-align: center;
            justify-self: center;

            $breakpoint-tablet: 900px;

            @media (max-width: $breakpoint-tablet) {
                p {
                    font-size: 4vmin !important;
                }
            }
        }
    }
}